import React from 'react';

const Call: React.FC = () => {
  React.useEffect(() => {
    window.location.replace(
      'https://cemtuncelli.notion.site/Beyond-Founding-Engineering-Team-ece71af9c54f432ba24602045a8fdf72',
    );
  }, []);
  return <></>;
};

export default Call;
